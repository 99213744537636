
html {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  overflow: overlay;
}

body {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Gotham-Black';
  font-style: 'Black'; 
  src: local('Gotham-Medium'), url(./fonts/Gotham-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'Gotham-Book';
  font-style: 'Book'; 
  src: local('GothamNarrow-Book'), url(./fonts/GothamNarrow-Book.otf) format('opentype');
}